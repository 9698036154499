define("discourse/plugins/discourse-data-explorer/discourse/components/param-input/boolean-three", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@field.Select name={{@info.identifier}} as |select|>
    <select.Option @value="Y">
      {{i18n "explorer.types.bool.true"}}
    </select.Option>
    <select.Option @value="N">
      {{i18n "explorer.types.bool.false"}}
    </select.Option>
    <select.Option @value="#null">
      {{i18n "explorer.types.bool.null_"}}
    </select.Option>
  </@field.Select>
  */
  {
    "id": "Vk0kOv7R",
    "block": "[[[8,[30,1,[\"Select\"]],[[16,3,[30,2,[\"identifier\"]]]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,3,[\"Option\"]],null,[[\"@value\"],[\"Y\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"explorer.types.bool.true\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,3,[\"Option\"]],null,[[\"@value\"],[\"N\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"explorer.types.bool.false\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,3,[\"Option\"]],null,[[\"@value\"],[\"#null\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"explorer.types.bool.null_\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[3]]]]]],[\"@field\",\"@info\",\"select\"],false,[\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-data-explorer/discourse/components/param-input/boolean-three.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});