define("discourse/plugins/discourse-data-explorer/discourse/components/param-input/group-list-input", ["exports", "@glimmer/component", "@ember/service", "select-kit/components/group-chooser", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _groupChooser, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GroupListInput extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get allGroups() {
      return this.site.get("groups");
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <@field.Custom id={{@field.id}}>
          <GroupChooser
            @content={{this.allGroups}}
            @value={{@field.value}}
            @labelProperty="name"
            @valueProperty="name"
            @onChange={{@field.set}}
          />
        </@field.Custom>
      
    */
    {
      "id": "VqD0NBtA",
      "block": "[[[1,\"\\n    \"],[8,[30,1,[\"Custom\"]],[[16,1,[30,1,[\"id\"]]]],null,[[\"default\"],[[[[1,\"\\n      \"],[8,[32,0],null,[[\"@content\",\"@value\",\"@labelProperty\",\"@valueProperty\",\"@onChange\"],[[30,0,[\"allGroups\"]],[30,1,[\"value\"]],\"name\",\"name\",[30,1,[\"set\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@field\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-data-explorer/discourse/components/param-input/group-list-input.js",
      "scope": () => [_groupChooser.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = GroupListInput;
});